import Catalogue from "@/dto/Catalogue";
import CatalogueOption from "@/dto/CatalogueOption";
import StrapiServices from "@/enumerators/strapiServices";
import StaticText from "@/enumerators/export";
import axios from "axios";
import API from "@/plugins/axios";
import session from "@/services/session";

const fieldService = {
  getFieldOption: (key: string, fieldlist: any) => {
    const catalogue = new Catalogue();
    if (fieldlist && fieldlist.length > 0) {
      const field = fieldlist.filter((f: { key: string }) => f.key == key);

      catalogue.key = key;
      field[0]?.pipedriveOption.map((op: { label: any; pipedriveId: any }) => {
        const catalogueOption = new CatalogueOption();
        catalogueOption.text = op.label;
        catalogueOption.value = op.pipedriveId;

        catalogue.catalogueOption.push(catalogueOption);
      });
    }

    return catalogue;
  },
  getProvidersOption: (fieldlist: any, nameField = "name") => {
    const catalogue = new Catalogue();
    if (fieldlist && fieldlist.length > 0) {
      fieldlist.map((op) => {
        const catalogueOption = new CatalogueOption();
        catalogueOption.text = op[nameField];
        catalogueOption.value = op.id;

        catalogue.catalogueOption.push(catalogueOption);
      });
    }

    return catalogue;
  },
  getSelectedOption: (
    value: number | string,
    optionList: Array<CatalogueOption>
  ): number => {
    let option: number = null;
    const result = optionList.find(
      (option: CatalogueOption) => option.value == value
    );
    if (result) {
      option = Number(result.value);
    }
    return option;
    // return Number(option.value);
  },
  getMultipleSelectedOption: (
    multiValue: any,
    optionList: Array<CatalogueOption>
  ): Array<number> => {
    const options = new Array<number>();
    multiValue?.map((value: any) => {
      options.push(
        fieldService.getSelectedOption(value.value ?? value, optionList)
      );
    });
    return options;
  },

  getOptionLabel: (
    key: string,
    pipedriveOptionId: number,
    pipedriveListField: any
  ) => {
    if (pipedriveListField) {
      const pipedriveField = pipedriveListField.find((p: any) => p.key == key);
      if (pipedriveField) {
        return pipedriveField.pipedriveOption.find(
          (option: any) => option.pipedriveId == pipedriveOptionId
        );
      }
    }

    return null;
  },
  getAllPipedriveField: async () => {
    const personField = API.strapi(session.getStoredToken())
      .get(StrapiServices.PERSON_FIELD)
      .catch((err) => {
        console.log(err);
        if (err.response.statusText === StaticText.UNAUTHORIZED)
          session.logout();
      });
    const organizationField = API.strapi(session.getStoredToken())
      .get(StrapiServices.ORGANIZATION_FIELD)
      .catch((err) => {
        console.log(err);
        if (err.response.statusText === StaticText.UNAUTHORIZED)
          session.logout();
      });
    const dealField = API.strapi(session.getStoredToken())
      .get(StrapiServices.DEAL_FIELD)
      .catch((err) => {
        console.log(err);
        if (err.response.statusText === StaticText.UNAUTHORIZED)
          session.logout();
      });
    const providersField = API.strapi(session.getStoredToken())
      .get(StrapiServices.PROVIDERS)
      .catch((err) => {
        console.log(err);
        if (err.response.statusText === StaticText.UNAUTHORIZED)
          session.logout();
      });
    const currencyExchange = API.strapi(session.getStoredToken())
      .get(StrapiServices.Currency)
      .catch((err) => {
        console.log(err);
        if (err.response.statusText === StaticText.UNAUTHORIZED)
          session.logout();
      });
    const resultField = await axios
      .all([
        personField,
        organizationField,
        dealField,
        providersField,
        currencyExchange,
      ])
      .catch((err) => {
        console.log(err);
        if (err.response.statusText === StaticText.UNAUTHORIZED)
          session.logout();
      });
    // console.log("resultField: ", resultField);
    return {
      person: resultField[0].data.pipedriveField,
      organization: resultField[1].data.pipedriveField,
      deal: resultField[2].data.pipedriveField,
      providers: resultField[3].data,
      currencyExchange: resultField[4].data,
    };
  },
};

export default fieldService;
