

























import { defineComponent, reactive } from "@vue/composition-api";
import { useActions } from "vuex-composition-helpers";
import API from "@/plugins/axios";
import LoginInput from "../inputs/Login";
import StrapiServices from "../enumerators/strapiServices";
import userService from "../services/users";
// import CryptoJS from "crypto-js";
import format from "../services/format";

export default defineComponent({
  setup() {
    const { SET_USER } = useActions(["SET_USER"]);

    const validate = async () => {
      // const secret = await API.strapi(process.env.VUE_APP_STRAPI_TOKEN)
      //   .get(StrapiServices.FIND_CONFIG)
      //   .then((res) => res.data.secret);

      // const loginInput: LoginInput = {
      //   identifier: CryptoJS.AES.encrypt(form.email, secret).toString(),
      //   password: CryptoJS.AES.encrypt(form.password, secret).toString(),
      // };

      const loginInput: LoginInput = {
        identifier: form.email,
        password: form.password,
      };

      API.strapi(null)
        .post(StrapiServices.USER_LOGIN, loginInput)
        .then((res) => {
          // console.log("res: ", res);
          const user = userService.createUser(res.data.data.user);
          SET_USER(user);
          const item = {
            value: res.data.data.jwt,
            expiry: format.getExpiry(),
          };
          localStorage.setItem("token", JSON.stringify(item));
        })
        .catch((err) => {
          alert("Login failed");
          console.log("Login failed | email: ", form.email);
          console.log(err);
          return false;
        });

      return true;
    };
    const form = reactive({
      email: "",
      password: "",
    });

    return { form, validate };
  },
});
