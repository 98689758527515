import { render, staticRenderFns } from "./Step5.vue?vue&type=template&id=32b616d3&scoped=true&"
import script from "./Step5.vue?vue&type=script&lang=ts&"
export * from "./Step5.vue?vue&type=script&lang=ts&"
import style0 from "./Step5.vue?vue&type=style&index=0&id=32b616d3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32b616d3",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpeedDial } from 'vuetify/lib/components/VSpeedDial';
installComponents(component, {VBtn,VContainer,VIcon,VSpeedDial})
