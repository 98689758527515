import axios, { AxiosInstance } from "axios";

const API = {
  strapi: (token: string | null): AxiosInstance => {
    if (token === null) {
      return axios.create({
        baseURL: process.env.VUE_APP_STRAPI_URL,
      });
    }

    return axios.create({
      baseURL: process.env.VUE_APP_STRAPI_URL,
      headers: {
        Authorization: "Bearer " + token,
      },
    });
  },
  pipedrive: (): AxiosInstance => {
    return axios.create({
      baseURL: process.env.VUE_APP_PIPEDRIVE_API,
      headers: {
        Authorization: "Bearer " + process.env.VUE_APP_PIPEDRIVE_TOKEN,
      },
    });
  },
};

export default API;
