<template>
  <img
    class="checkmark"
    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAA1ElEQVRIie3VOwrCQBRA0SvuwJW4AkvRUhHtVCzE1v8fwc4fbsSFuAIXYqNoYiycwCNFJo4TbHLbvJw3DIFA0h/LAal0THgbOAGZOPAW4AIeMLGNNwU+tY03BD6zjdeAp8LntvGqwBe28YrAl2GDWQNcnjwUXwMOUP8CLwMPha90w2M16PD5EnSVBL6JeqKResHVLCkCdzW7jYr7DTVLCgLffYv7DRTwAjoB/Kae7U1xv35gSV7gh19xP3ldxneuq6tgDziaImH/gzNwBS5Az3RBkrY3LltA/9XJVDYAAAAASUVORK5CYII="
  />
</template>
<script>
import { defineComponent } from "@vue/composition-api";
export default defineComponent({
  props: [],
  setup(props) {
    return { props };
  },
});
</script>
<style lang="scss" scoped>
@import "../common/common.scss";
.checkmark {
  width: 15px;
  vertical-align: middle;
  margin-bottom: 5px;
  padding: 3px;
}
</style>
