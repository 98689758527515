import User from "@/dto/User";

export default {
  createUser: (userData: {
    email: string;
    username: string;
    pipedriveId: number;
    admin: boolean;
  }): User => {
    const { email, username, pipedriveId, admin } = userData;
    const user = new User();
    user.email = email;
    user.username = username;
    user.pipedriveId = pipedriveId;
    user.admin = admin;
    return user;
  },
  getOwnerData: (pipedriveId: number, data: any[]): any[] => {
    return data.filter((i: any) => i.item.owner?.id == pipedriveId);
  },
};
