<template>
  <img
    class="pricedrop"
    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAACXElEQVRoge3ZvWsUQRjH8U9exAiCiBCroEIw1ioWdoKKoCCIChb+BxYWiqWxVkTLgK1NbFMIvoBoBIVgI2KjGLAQGzHgK5qz2Btdj3tNnt3bE38wsLO793vmuzOzz8wenTWLWp/LbKdGDncBMhD6D1I1/QepmsoAuYkDuFVkkDJAXuMuFosM8s8MrW60koT4CVewB+vqPptwCk9X4NcxIRYBsogdbfxGcD0aJHpoLeMYXra55yfO4l5k4GiQ21ho8D+HGUzmztcwHRl4NNIMjxrqh3G5frwbu3LX5vEBGyMCR/fIUkN9W+54J9bn6jW8igoc3SPjDfWHsnmTHthjfM9d3x4cv616eWvNN/n9EbzpwaMSb629ONpwbg5TOIO3wfF6Uq955CMOtvAak+WQ5R49+5IQa7JcMYOJFp4n8WMQQFL5hhvY2sT36iCBpPIVxxt8t0SCRE72F7J9RyonctfW4lrD/Yt4HxU8Mo+MyvYdScOyib+hXh/HkOwJJ32OCh7ZI5OypXrSMi750/A5f0OswebA+Katfg6kcr6J/xT21Rue16HAuL8XoFEwS9rvRZLG8CwaIhrmnWxZ0koTuF8URDRMDc9l292UFE/LvqR8KRoi6UIgTA37675PAj0vdoIoAiYapGuIpKhhtoA7spxS+HBqpehhVmpPVBFm1RBVgAmD6CdMOEQ/YAqDKBOmcIgyYEqDSCoCpnSIpEiYvkEkRcD0HSJpNTCVgUhaCUzlIJJ6gaksRFI3MJWHSGoHMzAQSc1gBg4iKQ9TKMRIkeayP36G8ED2sa4w/QIDj1BDogLOAAAAAABJRU5ErkJggg=="
  />
</template>
<script>
import { defineComponent } from "@vue/composition-api";
export default defineComponent({
  props: [],
  setup(props) {
    return { props };
  },
});
</script>
<style lang="scss" scoped>
@import "../common/common.scss";
.pricedrop {
  margin-top: 5px;
  width: 15px;
  vertical-align: middle;
  float: right;
}
</style>
