<template>
  <div class="breakdown">
    <v-card>
      <v-card-title class="text-h5 lighten-2 header">
        Annual Premium Breakdown
      </v-card-title>
      <v-row>
        <v-data-table
          :headers="headers"
          :items="props.breakdownList"
          :hide-default-footer="true"
        ></v-data-table>
      </v-row>
      <v-divider></v-divider>
      <v-row class="lastRow d-flex flex-column">
        <div class="col-12  d-flex" v-if="hasDiscount">
          <div class="col-2">Discount:</div>
          <div v-if="props.discount.amount" class="col-8">
            ${{ format.addNumericSeparator(props.discount.amount) }}
          </div>
          <div v-if="props.discount.percent" class="col-8">
            {{ props.discount.percent }}%
          </div>
        </div>
        <div class="col-12  d-flex">
          <div class="col-2">Total:</div>
          <div class="col-8">
            ${{ format.addNumericSeparator(format.handleDiscount(props.premium, props.discount)) }}
          </div>
        </div>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import format from "../services/format";
import { defineComponent, ref } from "@vue/composition-api";

export default defineComponent({
  props: ["breakdownList", "premium", "discount"],
  setup(props) {
    const headers = [
      { text: "Person (age, gender)", value: "info" },
      { text: "Basic Premium", value: "basic" },
      { text: "Out-Patient Premium", value: "outpatient" },
      { text: "Maternity Premium", value: "maternity" },
      { text: "Dental/Optical Premium", value: "dental" },
    ];

    const hasDiscount = props.discount.amount || props.discount.percent;

    return {
      headers,
      props,
      hasDiscount,
      format,
    };
  },
});
</script>
<style lang="scss" scoped>
@import "../common/common.scss";
.breakdown {
  .header {
    justify-content: center;
    background: $main-red;
    color: white;
    font-weight: 600;
  }
  .row {
    margin: 15px;
    padding-bottom: 20px;
    white-space: pre-wrap;
  }
}
</style>
