






























import Organization from "@/dto/Organization";
import Catalogue from "@/enumerators/catalogue";
import pipedriveField from "@/enumerators/pipedriveField";
import pipedriveServices from "@/enumerators/pipedriveServices";
import UserService from "../services/users";
import API from "@/plugins/axios";
import fieldService from "@/services/field";
import {
  defineComponent,
  reactive,
  watchEffect,
  ref,
} from "@vue/composition-api";
import { useActions, useGetters } from "vuex-composition-helpers";
import Clients from "./Clients.vue";
import StepControl from "./StepControl.vue";

export default defineComponent({
  props: ["user", "pipedriveFieldInfo", "step"],
  components: {
    Clients,
    StepControl,
  },
  setup(props) {
    const { search } = useGetters(["search"]);
    // const { SET_STEP } = useActions(["SET_STEP"]);
    const client = reactive({
      individual: {},
      organization: {},
      family: {},
    });
    let loading = ref(false);
    loading.value = true;

    const searchPerson = async (field, value) => {
      const params = {
        term: value,
        fields: field,
        exact_match: "false",
        start: "0",
        api_token: process.env.VUE_APP_PIPEDRIVE_TOKEN,
      };
      return await API.pipedrive().get(
        pipedriveServices.SEARCH_PERSON + new URLSearchParams(params)
      );
    };

    // watch(clientSelection, () => SET_STEP(3));

    // const getDeal = () => SET_STEP(3);

    watchEffect(async () => {
      // console.log(client);

      if (!search.value.field.length || !search.value.value) {
        client.individual = {};
        client.organization = {};
        client.family = [];
        return;
      }
      //SET_STEP(props.step);
      let result = (await searchPerson(search.value.field, search.value.value))
        ?.data.data.items;
      // console.log("result = ", result);
      if (result) {
        //only display contact belong to current user
        if (!props.user.admin) {
          result = UserService.getOwnerData(props.user.pipedriveId, result);
        }

        // //seperate individual
        const individual = result?.filter(
          (persons: any) => !persons.item.organization?.id
        );
        const { family, organization } = await filterOrganization(result);
        client.individual = individual.sort((a, b) => a.item.id - b.item.id);
        client.organization = organization
          .map((m: any) => m.organization)
          .sort((a: any, b: any) => b.item.id - a.item.id);
        client.family = family
          .map((m: any) => m.organization)
          .sort((a: any, b: any) => b.item.id - a.item.id);
      } else {
        loading.value = false;
      }
    });

    const filterOrganization = async (result) => {
      //separate organization
      const organizationNoFilter = result.filter(
        (persons: any) => persons.item.organization?.id
      );

      const organization: Organization[] = [];
      const family: Organization[] = [];
      const organizationIds: any[] = [
        ...new Set(
          organizationNoFilter.map(
            (persons: any) => persons.item.organization?.id
          )
        ),
      ];

      // get information about each organization
      let resultOrganization = [];
      let promise = organizationIds.map(
        async (id: number): Promise<any> => {
          //const organizationDetail = new Organization();
          const result = await API.pipedrive().get(
            pipedriveServices.ORGANIZATIONS +
              id +
              "?api_token=" +
              process.env.VUE_APP_PIPEDRIVE_TOKEN
          );
          resultOrganization.push(result?.data?.data);
        }
      );
      await Promise.all(promise).catch((err) => console.log((err) => err));

      // for each person with family organization
      organizationNoFilter.map((organizationItem) => {
        const org = resultOrganization.find(
          (result) => result.id === organizationItem.item.organization.id
        );
        let organizationDetail: any = {};
        organizationDetail.type = fieldService.getOptionLabel(
          pipedriveField.ORGANIZATION_FAMILY,
          org[pipedriveField.ORGANIZATION_FAMILY],
          props.pipedriveFieldInfo.organization
        );
        organizationDetail.name = org.name;
        organizationDetail.people_count = org.people_count;
        organizationDetail.value = org.id;
        organizationDetail.organization = organizationItem;
        if (
          org[pipedriveField.ORGANIZATION_FAMILY] ==
          Catalogue.OrganizationType.ORGANIZATION
        ) {
          organization.push(organizationDetail);
        } else if (
          org[pipedriveField.ORGANIZATION_FAMILY] ==
          Catalogue.OrganizationType.FAMILY
        ) {
          family.push(organizationDetail);
        }
      });
      loading.value = false;
      return { family, organization };
    };
    return { client, loading };
  },
});
