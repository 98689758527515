
















































import { defineComponent } from "@vue/composition-api";
import fieldService from "@/services/field";
import { useActions, useGetters } from "vuex-composition-helpers";

export default defineComponent({
  props: ["title", "deal", "dealField"],

  setup(props) {
    const { SET_DEALSELECTION } = useActions(["SET_DEALSELECTION"]);
    const { dealSelection } = useGetters(["dealSelection"]);
    const getLabel = (value: number, key: string) =>
      fieldService.getOptionLabel(key, value, props.dealField)?.label;

    const selection = (selection: any) => SET_DEALSELECTION(selection);

    const isActive = (id: number) => dealSelection.value.id == id;
    return {
      props,
      getLabel,
      selection,
      isActive,
    };
  },
});
