enum StrapiServices {
  LOGIN = "/auth/local",
  FIND_CONFIG = "/configuration",
  USER_LOGIN = "/users/login",
  USER_ME = "/users/me",
  SEARCH_PEOPLE = "/pipedrive/people/search/",
  SEARCH_ORGANIZATION = "/pipedrive/organization/search/",
  PERSON_FIELD = "/person-field",
  DEAL_FIELD = "/deal-field",
  ORGANIZATION_FIELD = "/organization-field",
  CREATE_QUOTATION_NUMBER = "/quotation-numbers/create",
  Currency = "/currencies",
  PROVIDERS = "/providers",
}
export default StrapiServices;
