<template>
  <div>
    <div v-if="!pipedriveFieldInfo.loading">
      <Progression :progress="step" />
      <!-- user={{ user }} search = {{ search }} -->

      <div class="quotationTool">
        <transition name="card">
          <Step1
            v-if="user.username && step == 1"
            :user="user"
            :pipedriveFieldInfo="pipedriveFieldInfo"
            step="1"
          />
        </transition>
        <transition name="card">
          <Step2
            v-if="step == 2"
            step="2"
            :user="user"
            :pipedriveFieldInfo="pipedriveFieldInfo"
          />
        </transition>

        <transition name="card">
          <Step3
            v-if="step == 3"
            step="3"
            :pipedriveFieldInfo="pipedriveFieldInfo"
          />
        </transition>
        <transition name="card">
          <Step4
            v-if="step == 4"
            step="4"
            :pipedriveFieldInfo="pipedriveFieldInfo"
          />
        </transition>
        <transition name="card">
          <Step5 v-if="step == 5" step="5" />
        </transition>
        <transition name="card">
          <QuotationExport v-if="step == 6" ref="exportComp" />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, onMounted } from "@vue/composition-api";
import { useGetters, useActions } from "vuex-composition-helpers";
import StepControl from "../components/StepControl";
import fieldService from "../services/field";
import Step1 from "../components/Step1";
import Step2 from "../components/Step2";
import Step3 from "../components/Step3";
import Step4 from "../components/Step4";
import Step5 from "../components/Step5";
import QuotationExport from "./QuotationExport";
import format from "../services/format";
import Progression from "../components/Progression";
export default defineComponent({
  components: {
    StepControl,
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
    QuotationExport,
    Progression,
  },
  setup() {
    const { user, search, step } = useGetters(["user", "search", "step"]);
    const {
      SET_BENEFITS_NAMES,
      SET_AREA,
      SET_MIX_OPTIONS,
      SET_NETWORK,
    } = useActions([
      "SET_BENEFITS_NAMES",
      "SET_AREA",
      "SET_MIX_OPTIONS",
      "SET_NETWORK",
    ]);

    const pipedriveFieldInfo = reactive({
      person: {},
      organization: {},
      deal: {},
      providers: {},
      currencyExchange: {},
      loading: true,
    });
    onMounted(async () => {
      const {
        person,
        organization,
        deal,
        providers,
        currencyExchange,
      } = await fieldService.getAllPipedriveField();
      pipedriveFieldInfo.person = person;
      pipedriveFieldInfo.organization = organization;
      pipedriveFieldInfo.deal = deal;
      pipedriveFieldInfo.providers = providers;
      pipedriveFieldInfo.currencyExchange = currencyExchange;
      pipedriveFieldInfo.loading = false;

      const benef = await format.getBenefitsName();
      const areas = await format.getArea();
      const networks = await format.getNetwork();
      const mixingLevels = await format.getMixingLevel();

      SET_BENEFITS_NAMES(benef);
      SET_AREA(areas.data);
      SET_NETWORK(networks.data);
      SET_MIX_OPTIONS(mixingLevels.data);
    });
    window.onbeforeunload = function() {
      //console.log("step: ", step);
      if (step.value != 1) return "Are you sure you want to leave this page?";
    };

    return { step, user, pipedriveFieldInfo, search };
  },
});
</script>

<style lang="scss">
@import "../common/common.scss";

.progressionBar {
  display: flex;
  overflow: visible;
}
</style>
