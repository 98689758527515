const Catalogue = {
  LeadDealType: {
    DEAL: "deal",
    LEAD: "lead",
  },
  DealStatus: {
    WON: "WON",
    OPEN: "open",
  },
  OrganizationType: {
    ORGANIZATION: 70,
    FAMILY: 71,
  },
  PatientType: {
    IN_PATIENT: 1,
    OUT_PATIENT: 2,
  },
  UnitValue: {
    PERCENTAGE: "%",
  },
};
export default Catalogue;
