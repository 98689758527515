

















































































































import { defineComponent, onMounted, ref } from "@vue/composition-api";
import format from "../services/format";
export default defineComponent({
  props: ["plans", "defaultColor", "label"],
  setup(props) {
    const getId = props.label + "-id";
    const rowHeight = ref(null);
    const policyFocusing = ref(null);
    onMounted(() => {
      rowHeight.value = document.getElementById(getId)?.offsetHeight;
    });

    const gatherFirst = () => props.plans?.map((p) => p.first);
    const gatherSecond = () => props.plans?.map((p) => p.second);
    const gatherThird = () => props.plans?.map((p) => p.third);

    return {
      props,
      rowHeight,
      getId,
      format,
      policyFocusing,
      gatherFirst,
      gatherSecond,
      gatherThird,
    };
  },
});
