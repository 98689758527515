






















































import PersonDetails from "@/dto/PersonDetails";
import pipedriveField from "@/enumerators/pipedriveField";
import pipedriveServices from "@/enumerators/pipedriveServices";
import API from "@/plugins/axios";
import convert from "@/services/convert";
import { defineComponent, ref } from "@vue/composition-api";
import { useActions, useGetters } from "vuex-composition-helpers";

export default defineComponent({
  props: ["clients", "title", "isOrganization"],
  setup(props) {
    const { SET_CLIENTSELECTION, SET_STEP, SET_IS_ORGANIZATION } = useActions([
      "SET_CLIENTSELECTION",
      "SET_STEP",
      "SET_IS_ORGANIZATION",
    ]);
    const { clientSelection } = useGetters(["clientSelection"]);
    const selecting = ref(null);
    const isSelecting = (person) => person?.item?.id == selecting.value;
    const selection = async (person) => {
      if (!person?.item?.id) return;
      selecting.value = person?.item?.id;
      const clientDetail = new PersonDetails();
      const resultPerson = await API.pipedrive().get(
        pipedriveServices.PERSONS +
          person?.item?.id +
          "?api_token=" +
          process.env.VUE_APP_PIPEDRIVE_TOKEN
      );

      clientDetail.name = resultPerson.data.data[pipedriveField.NAME];
      clientDetail.age = convert.dateToAge(
        resultPerson.data.data[pipedriveField.BIRTHDAY]
      );
      clientDetail.id = resultPerson.data.data.id;
      clientDetail.gender = convert.toCatalogue(
        pipedriveField.GENDER,
        resultPerson.data.data[pipedriveField.GENDER]
      );
      clientDetail.Organization = resultPerson.data.data.org_id;
      SET_IS_ORGANIZATION(props.isOrganization);
      SET_CLIENTSELECTION(clientDetail);
      SET_STEP(3);
      selecting.value = null;
      return true;
    };
    const isSelected = (person) =>
      selecting.value !== null
        ? false
        : person?.item?.id == clientSelection.value?.id;
    return { props, selection, isSelected, isSelecting };
  },
});
