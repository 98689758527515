<template>
  <div class="discount">
    <v-card>
      <v-card-title class="text-h5 lighten-2 header">
        Apply Discount
      </v-card-title>

      <v-row>
        <v-icon class="icon" size="middle">mdi-currency-usd</v-icon>
        <v-text-field
          v-model="discountInput.amount"
          type="number"
          label="Amount off"
          :disabled="disableAmount"
          min="0"
        ></v-text-field>
        <span class="seperator">or</span>
        <v-icon class="icon" size="middle">mdi-percent-outline</v-icon>
        <v-text-field
          v-model="discountInput.percent"
          type="number"
          label="Percentage off"
          min="0"
          :disabled="disablePercentage"
        ></v-text-field>
      </v-row>

      <v-row>
        <v-checkbox
          v-model="discountInput.displayOriginal"
          label="Show original price"
        />
      </v-row>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="second-button" text @click="applyDiscount()">
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import format from "../services/format";
import { defineComponent, computed, reactive } from "@vue/composition-api";
import { useGetters, useActions } from "vuex-composition-helpers";

export default defineComponent({
  props: ["updateDialog", "premiumDetails", "expandRow"],
  setup(props) {
    const { premiums } = useGetters(["premiums"]);
    const { SET_PREMIUMS } = useActions(["SET_PREMIUMS"]);
    const discount = props.premiumDetails.discount;
    const discountInput = reactive({
      percent: discount.percent,
      amount: discount.amount,
      displayOriginal: false,
    });

    const disableAmount = computed(() => discountInput.percent.length > 0);
    const disablePercentage = computed(() => discountInput.amount.length > 0);

    const applyDiscount = () => {
      if (
        parseFloat(discountInput.percent) <= 0 ||
        parseFloat(discountInput.amount) <= 0
      ) {
        alert("Value cannot be smaller than 0, please enter again");
        return;
      }
      if (discountInput.displayOriginal) props.expandRow();
      SET_PREMIUMS(
        format.updatePremium(
          props.premiumDetails.policyID,
          "discount",
          discountInput,
          premiums
        )
      );
      props.updateDialog(
        false,
        props.premiumDetails.policyID,
        "discountDialog"
      );
    };

    return {
      discountInput,
      disableAmount,
      disablePercentage,
      applyDiscount,
    };
  },
});
</script>
<style lang="scss" scoped>
@import "../common/common.scss";
.discount {
  .header {
    justify-content: center;
    background: $main-red;
    color: white;
    font-weight: 600;
  }
  .row {
    margin: 15px;
    .icon {
      align-self: center;
      padding-right: 2%;
    }
    .seperator {
      align-self: center;
      padding: 5%;
    }
    .v-input--is-disabled {
      opacity: 0.5;
      -moz-opacity: 0.5;
    }
  }
  .second-button {
    color: $main-red;
    border-color: $main-red;
    text-align: center;
  }
}

@media print {
  .discount {
    display: none;
  }
}
</style>
