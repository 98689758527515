import catalogue from "../enumerators/catalogue";
import staticText from "../enumerators/export";
import staticTextFrench from "../enumerators/exportFr";
import jsPDF from "jspdf";
import API from "@/plugins/axios";
import session from "@/services/session";
// import { regular, medium, bold } from "../assets/fonts/Roboto";
import { regular, medium, bold } from "../assets/fonts/OpenSans";
import benefitsId from "@/enumerators/benefitsId";
import { useGetters } from "vuex-composition-helpers";

const format = {
  interpretAnnualLimit: (plans: Array<any>): Array<any> => {
    //TODO: adapt changes
    return plans.map((plan) =>
      plan.annualLimitUnlimited
        ? format.getStaticText("UNLIMITED")
        : // : staticText.UPTO + plan.currency.symbol + plan.annualLimit.amount
        plan.annualLimit
        ? format.getStaticText("UPTO") +
          format.currencyConvert(plan.annualLimit, plan.rate, plan.currency)
        : "-"
    );
  },
  interpretAnnualPremium: (data: Array<any>): Array<any> => {
    const { isFrench } = useGetters(["isFrench"]);
    return data.map((plan) => {
      const origAPremium = {
        policyID: plan.id,
        annualPremium: null,
        family: null,
      };

      if (!plan.premium[0].manualPrice) {
        origAPremium.annualPremium = isFrench.value
          ? Math.round(plan.annualPremium / plan.rate)
          : plan.annualPremium;

        origAPremium.family = plan.premium;
      }
      // if (plan.premium.length > 1 && !plan.premium[0].manualPrice) {
      //   origAPremium.family = plan.premium;
      // }

      // const annualP = plan.family
      //   ? plan.totalFamilyPremium
      //   : plan.agePremium?.premium?.find(
      //       (p) => p.surchargePeriod === staticText.ANNUAL
      //     );

      // origAPremium.family = plan.family;

      // if (annualP)
      //   origAPremium.annualPremium = format.currencyConvert(
      //     annualP.premium ?? annualP,
      //     plan.currency.rate,
      //     plan.currency.name
      //   );
      // console.log("origAPremium: ", origAPremium);
      return origAPremium;
    });
  },
  interpretSurcharge: (data: Array<any>): Array<any> => {
    return data.map((plan) => {
      return format.getStaticText("NO_SURCHARGE");
      // const surcharges = plan.surcharge.filter(
      //   (p) =>
      //     p.surchargePeriod !== staticText.ANNUAL &&
      //     p.surchargePeriod !== staticText.MONTHLY
      // );
      // return surcharges && surcharges.length
      //   ? staticText.SURCHARGE_OF +
      //       surcharges
      //         .map(
      //           (s) =>
      //             s.surchargePercentage +
      //             catalogue.UnitValue.PERCENTAGE +
      //             // (s.surchargePercentage
      //             //   ? s.surchargePercentage + catalogue.UnitValue.PERCENTAGE
      //             //   : format.currencyConvert(
      //             //       s.premium,
      //             //       plan.currency.rate,
      //             //       plan.currency.name
      //             //     )) +
      //             (s.surchargePeriod ? " for " + s.surchargePeriod : "")
      //         )
      //         .join(", \n")
      //   : staticText.NO_SURCHARGE;
    });
  },
  interpretAnnualDeduct: (data: Array<any>): Array<any> => {
    return data.map((plan) =>
      format.currencyConvert(plan.deductible, plan.rate, plan.currency, true)
    );
  },
  interpretDeductOption: (data: Array<any>): Array<any> => {
    return data.map((plan) =>
      plan.deductibleOption.length
        ? plan.deductibleOption
            .split(";")
            .map((amount) =>
              amount
                ? format.currencyConvert(
                    amount,
                    plan.currency.rate,
                    plan.currency.name
                  )
                : null
            )
            .join("\n")
        : "-"
    );
  },
  interpretCopayOption: (data: Array<any>): Array<any> => {
    return data.map(
      (plan) => "-"
      // plan.coPaymentOption.amount
      //   ? plan.coPaymentOption.amount
      //       .map((amount) =>
      //         plan.coPaymentOption.symbol === catalogue.UnitValue.PERCENTAGE
      //           ? amount + catalogue.UnitValue.PERCENTAGE
      //           : format.currencyConvert(
      //               amount,
      //               plan.currency.rate,
      //               plan.currency.name
      //             )
      //       )
      //       .join("\n")
      //   : "-"
    );
  },
  interpretLimit: (limits: Array<any>, currency: any): string =>
    limits
      .map(
        (limit) =>
          (limit.labelName ? limit.labelName + " " : "") +
          (format.valueUnit(limit.value, limit.unitTypeSymbol, currency)
            ? format.valueUnit(limit.value, limit.unitTypeSymbol, currency) +
              " "
            : "") +
          // (limit.value ?? "") +
          (limit.frequencyName
            ? (limit.quantifierName
                ? limit.quantifierName?.toLowerCase() + " "
                : "") + limit.frequencyName
            : "") +
          (!limit.custom || !limit.custom.length
            ? ""
            : "\n" +
              "<span style='font-size:14px'>" +
              format.lowerFirstLetter(limit.custom) +
              "</span>")
      )
      .join("\n\n"),
  valueUnit: (
    value: number,
    unitTypeSymbol: string,
    currency: { rate: number; name: string }
    // custom: string,
    // quantifier: string
  ): string => {
    if (!value) return null;
    if (unitTypeSymbol === catalogue.UnitValue.PERCENTAGE) {
      return value + unitTypeSymbol;
    }
    if (!unitTypeSymbol) return value.toString();
    return format.currencyConvert(value, currency.rate, currency.name); //to be convet;
  },

  coverageInclude: (data: Array<any>): Array<string> => {
    const include = [];
    data.map((d) => {
      const coverage = [...format.gatherCoverage(d.areaInclude)];
      include.push(coverage);
    });
    return include;
  },
  coverageExclude: (data: Array<any>): Array<string> => {
    const exclude = [];
    data.map((d) => {
      const coverage = [...format.gatherCoverage(d.areaExclude)];
      exclude.push(coverage);
    });
    return exclude;
  },
  gatherCoverage: (coverage: Array<any>): Array<string> =>
    coverage?.map((c: any) => c.name),
  isEmpty: (obj: any): boolean => Object.keys(obj).length === 0,
  previewExport: (quotation: { value: number }): boolean => {
    const storeQuotation = JSON.parse(JSON.stringify(quotation.value));
    // console.log("storeQuotation.res.length: ", storeQuotation.res.length);
    if (storeQuotation.res.length > 6) {
      alert("Please select at most 6 policies");
      return false;
    }
    return true;
    // router.push("export");
  },
  generateReport: (quotation: any): void => {
    const pdfId = document.getElementById("pdfId");

    const detailHead = document.getElementById("Details-quotationHeader");
    const d1 = document.getElementById("Details-first");
    const d2 = document.getElementById("Details-second");
    const d3 = document.getElementById("Details-third");
    const head = document.getElementById("-quotationHeader");
    const h1 = document.getElementById("-first");
    const h2 = document.getElementById("-second");
    const h3 = document.getElementById("-third");
    if (d1) d1.style["height"] = d1.offsetHeight + 1 + "px";
    if (d2) d2.style["height"] = d2.offsetHeight + 1 + "px";
    if (d3) d3.style["height"] = d3.offsetHeight + 1 + "px";
    detailHead.style["height"] =
      d1?.offsetHeight + d2?.offsetHeight + d3?.offsetHeight + "px";
    if (h1) h1.style["height"] = h1.offsetHeight + 1 + "px";
    if (h2) h2.style["height"] = h2.offsetHeight + 1 + "px";
    if (h3) h3.style["height"] = h3.offsetHeight + 1 + "px";
    head.style["height"] =
      h1?.offsetHeight + h2?.offsetHeight + h3?.offsetHeight + "px";

    // quotationHeader.style["height"] = quotationHeader.offsetHeight - 20 + "px";
    // pdfId.style["font-size"] = "30px !important";
    const w = pdfId.offsetWidth + 10;
    const h = pdfId.offsetHeight + 50;
    const doc = new jsPDF("p", "px", [w, h]);

    doc.addFileToVFS("OpenSans-Regular.ttf", regular);
    doc.addFileToVFS("OpenSans-Medium.ttf", medium);
    doc.addFileToVFS("OpenSans-Bold.ttf", bold);
    doc.addFont("OpenSans-Regular.ttf", "OpenSans", "normal");
    doc.addFont("OpenSans-Medium.ttf", "OpenSans", "medium");
    doc.addFont("OpenSans-Bold.ttf", "OpenSans", "bold");
    // doc.addFont("OpenSans-Regular.ttf", "Roboto", "normal");
    // doc.addFont("OpenSans-Medium.ttf", "Roboto", "medium");
    // doc.addFont("OpenSans-Bold.ttf", "Roboto", "bold");

    doc.html(pdfId, {
      html2canvas: {
        width: 150,
        letterRendering: true,
        allowTaint: true,
        ignoreElements: (ele) => {
          if (
            ele.id === "exportBtn" ||
            ele.id === "stepControl" ||
            ele.id === "discount-btn" ||
            ele.id === "hideEye"
          )
            return true;
        },
      },
      callback: (pdf) => {
        pdf.save(staticText.QUOTATION_PDF_FILENAME + ".pdf");

        if (d1) d1.style["height"] = d1.offsetHeight - 1 + "px";
        if (d2) d2.style["height"] = d2.offsetHeight - 1 + "px";
        if (d3) d3.style["height"] = d3.offsetHeight - 1 + "px";
        detailHead.style["height"] =
          d1?.offsetHeight + d2?.offsetHeight + d3?.offsetHeight + "px";
        if (h1) h1.style["height"] = h1.offsetHeight - 1 + "px";
        if (h2) h2.style["height"] = h2.offsetHeight - 1 + "px";
        if (h3) h3.style["height"] = h3.offsetHeight - 1 + "px";
        head.style["height"] =
          h1?.offsetHeight + h2?.offsetHeight + h3?.offsetHeight + "px";

        format.updateQuotation("pdf", quotation, null, null);
      },
    });
  },
  updateQuotation: async (
    type: string,
    quotation: any,
    premiums: any,
    hiddenRows: any
  ): Promise<any> => {
    //save preview
    const { id, res } = JSON.parse(
      JSON.stringify(quotation.value ? quotation.value : quotation)
    );
    const body = {
      qId: id,
      body: res,
      premiums: premiums,
      hiddenRows: hiddenRows,
    };
    await API.strapi(session.getStoredToken())
      .post("/quotation-numbers/update/" + type, body)
      .catch((err) => {
        console.log(err);
        if (err.response.statusText === staticText.UNAUTHORIZED)
          session.logout();
      });
  },
  getBenefitBold: (name: string): boolean => {
    return false;
    // name === staticText.MATERNITY
  },
  getBenefitColor: (name: string): string => {
    return "";
    // name === staticText.DENTAL || name === staticText.OPTICAL ? "grey-bg" : "",
  },
  getPlansBenefits: (id: number, data: Array<any>): Array<any> =>
    data.map((plan: any) => {
      return {
        benefit: plan.benefits.find((ben: any) => ben.id === id),
        currency: { name: plan.currency, rate: plan.rate },
      };
    }),
  getHeaderPlans: (data: Array<any>): any =>
    data?.map((plan) => {
      const searchNReplace = [
        { search: plan.insuranceProvider, replace: "" },
        { search: "-", replace: " " },
        { search: "+", replace: "\n" },
      ];

      return {
        first:
          format.replaceAllCustom(plan.provider, "_", " ") +
          (plan.cfe ? " + " + format.getStaticText("CFE") : ""),
        second:
          (plan.inpatient && plan.inpatientLvlCoverage
            ? format.getStaticText("INPATIENT") +
              ": " +
              plan.inpatientLvlCoverage +
              "\n"
            : "") +
          (plan.outpatient && plan.outpatientLvlCoverage
            ? format.getStaticText("OUTPATIENT") +
              ": " +
              plan.outpatientLvlCoverage +
              "\n"
            : "") +
          (plan.maternity && plan.maternityLvlCoverage
            ? format.getStaticText("MATERNITY") +
              ": " +
              plan.maternityLvlCoverage +
              "\n"
            : "") +
          (plan.dentalOptical && plan.dentalOpticalLvlCoverage
            ? format.getStaticText("DENTAL_OPTICAL") +
              ": " +
              plan.dentalOpticalLvlCoverage
            : ""),
        third:
          plan.areaInclude.map((area) => area.name).join(", ") +
          "\n" +
          (plan.areaExclude.length > 0
            ? format.getStaticText("EXCLUDING") + "\n"
            : "") +
          plan.areaExclude.map((area) => area.name).join(", "),
      };
    }),
  getHeaderPlansDetails: (data: Array<any>): any =>
    data.map((plan) => {
      return {
        first: plan.network ?? "-",
        second: plan.roomType ?? "-",
      };
    }),
  hasOutPatient: (data: Array<any>): any =>
    data.map((plan) => {
      return plan.benefits.benefitList?.find((ben) => ben.type == 2)
        ? true
        : false;
    }),
  filterByType: (arr: Array<any>, id: number): Array<any> =>
    arr.filter((b: any) => b.benefitType?.id == id),

  sortByName: (arr: Array<any>): Array<any> =>
    arr.sort((a: any, b: any) =>
      a.name.toLowerCase() > b.name.toLowerCase ? 1 : -1
    ),
  sortByOrder: (arr: Array<any>): Array<any> =>
    arr.sort((a: any, b: any) => {
      if (a.order == null) return 1;
      if (b.order == null) return -1;
      return a.order > b.order ? 1 : -1;
    }),

  getCurrency: (currency: any): string => currency?.name + currency?.symbol,
  getExpiry: (): number =>
    new Date().getTime() + parseInt(process.env.VUE_APP_TOKEN_TTL),

  currencyConvert: (
    amount: number,
    rate: number,
    currency: string,
    isUSD = false
  ): string => {
    if (!amount || amount === 0) return "-";
    if (!rate)
      return format.getStaticText("DOLLAR_SIGN") + amount.toLocaleString();

    const { isFrench } = useGetters(["isFrench"]);
    const newAmount = isUSD
      ? Math.round(amount / rate)
      : Math.round(amount * rate);
    if (isUSD)
      return (
        format.getStaticText("DOLLAR_SIGN") +
        (isFrench.value ? newAmount.toLocaleString() : amount.toLocaleString())
      );
    else if (isFrench.value || currency !== "HKD")
      return format.getStaticText("DOLLAR_SIGN") + amount.toLocaleString();
    else
      format.getStaticText("DOLLAR_SIGN") +
        newAmount.toLocaleString() +
        (" (" + currency + amount.toLocaleString() + ")");

    // if (currency !== "HKD")
    //   return format.getStaticText("DOLLAR_SIGN") + amount.toLocaleString();
    // //e.g. $100 (HKD775)
    // return (
    //   format.getStaticText("DOLLAR_SIGN") +
    //   // staticText.DOLLAR_SIGN +
    //   (opposite ? amount.toLocaleString() : newAmount.toLocaleString()) +
    //   (" (" +
    //     currency +
    //     (opposite ? newAmount.toLocaleString() : amount.toLocaleString()) +
    //     ")")
    // );
  },
  addNumericSeparator: (amount) =>
    amount ? parseFloat(amount).toLocaleString() : amount,
  isEmptyRow: (plans, key) => {
    let isEmpty = true;
    plans.map((plan) => {
      if (key != null && plan[key] && plan[key]?.trim() !== "-")
        isEmpty = false;
      else if (key == null && plan && plan?.trim() !== "-") isEmpty = false;
    });
    return isEmpty;
  },
  handleDiscount: (orig, discount) => {
    const annualPremium = orig.annualPremium;
    const discountObj = format.getDiscount(discount);
    if (discountObj) {
      if (discountObj.type == "amount") {
        return (
          // staticText.DOLLAR_SIGN +
          annualPremium - discountObj.value
          // format.getOldPrice(annualPremium) - discountObj.value
          // .toLocaleString()
        );
      } else if (discountObj.type == "percent" && discountObj.value > 0) {
        return Math.round(
          annualPremium * (1 - discountObj.value / 100)
          // format.getOldPrice(annualPremium) * (1 - discountObj.value / 100)
        );
        // .toLocaleString()
      }
    }
    return orig.annualPremium;
  },
  getOldPrice: (orig) => {
    let price = orig;
    if (orig.includes("(") && orig.includes(")")) price = orig.split(" ")[0];
    price = price.replace(staticText.DOLLAR_SIGN, "").replace(/\D/g, "");
    price = price
      .replace(format.getStaticText("DOLLAR_SIGN"), "")
      .replace(/\D/g, "");
    // console.log("getOldPrice | price: ",price)
    return parseFloat(price);
  },
  getDiscount: (discount) => {
    return discount?.amount?.length
      ? { type: "amount", value: parseFloat(discount?.amount) }
      : discount?.percent?.length
      ? { type: "percent", value: parseFloat(discount?.percent) }
      : null;
  },
  getBenefitsName: async () => {
    return await API.strapi(session.getStoredToken())
      .get("/benefits-names")
      .catch((err) => {
        console.log(err);
        if (err.response.statusText === staticText.UNAUTHORIZED)
          session.logout();
      });
  },
  getArea: async () => {
    return await API.strapi(session.getStoredToken())
      .get("/areas")
      .catch((err) => {
        console.log(err);
        if (err.response.statusText === staticText.UNAUTHORIZED)
          session.logout();
      });
  },
  getMixingLevel: async () => {
    return await API.strapi(session.getStoredToken())
      .get("/policies/mixing")
      .catch((err) => {
        console.log(err);
        if (err.response.statusText === staticText.UNAUTHORIZED)
          session.logout();
      });
  },
  getNetwork: async () => {
    return await API.strapi(session.getStoredToken())
      .get("/networks")
      .catch((err) => {
        console.log(err);
        if (err.response.statusText === staticText.UNAUTHORIZED)
          session.logout();
      });
  },
  getPremiumList: (data) => {
    const origAPremium = {};
    data.map((policy) => {
      const premiumDetail = {
        policyID: policy.id,
        discountDialog: false,
        premiumDialog: false,
        breakdownDialog: false,
        origPremium: null,
        premium: null,
        discount: { amount: "", percent: "" },
        // family: policy.family,
      };

      if (!policy.premium[0].manualPrice) {
        premiumDetail.premium = policy.totalPrice;
        premiumDetail.origPremium = policy.totalPrice;
      }

      // const annualP = policy.family
      //   ? policy.totalFamilyPremium
      //   : policy.agePremium?.premium?.find(
      //       (p) => p.surchargePeriod == "Annual"
      //     );

      // if (annualP) {
      //   premiumDetail.premium = annualP.premium ?? annualP;
      //   premiumDetail.origPremium = annualP.premium ?? annualP;
      // }

      origAPremium[policy.id] = premiumDetail;
    });
    return origAPremium;
  },
  updatePremium: (id, key, val, premiums) => {
    const details = premiums.value[id];
    details[key] = val;
    premiums.value[id] = details;
    return premiums.value;
  },
  lowerFirstLetter: (text: string): string => {
    return text.charAt(0).toLowerCase() + text.slice(1);
  },
  replaceAllCustomArr: (
    val: string,
    searchNReplace: Array<{ search: string; replace: string }>
  ) => {
    if (!val) return val;
    let res = val;
    searchNReplace.map((item) => {
      // if (!item.search || !item.replace) {
      //   return res;
      // }
      res = res.split(item.search).join(item.replace);
    });

    return res.trim();
  },
  replaceAllCustom: (val: string, search: string, replace: string) =>
    val.split(search).join(replace),
  getMixOptions: (mixingLevels: any) => {
    const newLevels = JSON.parse(JSON.stringify(mixingLevels));
    newLevels.map((m) => {
      m.inpatientSelected = m.inpatient;
      m.outpatientSelected = m.outpatient;
      m.maternitySelected = m.maternity;
      m.dentalOpticalSelected = m.dentalOptical;
    });

    return newLevels;
  },
  getMixingReq: (mixing: any, providers: Array<number>, benefits: any) => {
    return mixing
      .map((m) => {
        return providers.find((p) => p === m.id)
          ? {
              company: m.id,
              in: m.inpatientSelected,
              out:
                benefits.includes(benefitsId.OUTPATIENT) ||
                benefits.includes(benefitsId.MATERNITY) ||
                benefits.includes(benefitsId.DENTAL) ||
                benefits.includes(benefitsId.OPTICAL)
                  ? m.outpatientSelected
                  : [],
              mat: benefits.includes(benefitsId.MATERNITY)
                ? m.maternitySelected
                : [],
              od:
                benefits.includes(benefitsId.DENTAL) ||
                benefits.includes(benefitsId.OPTICAL)
                  ? m.dentalOpticalSelected
                  : [],
            }
          : null;
      })
      .filter((mix) => mix);
  },
  checkMixOptions: (mixing: any, providers: Array<number>, benefits: any) => {
    let error = false;
    mixing.map((m) => {
      if (error) return;
      if (providers.find((p) => p === m.id)) {
        error =
          !m.inpatientSelected.length ||
          !(benefits.includes(benefitsId.OUTPATIENT) ||
          benefits.includes(benefitsId.MATERNITY) ||
          benefits.includes(benefitsId.DENTAL) ||
          benefits.includes(benefitsId.OPTICAL)
            ? m.outpatientSelected.length
            : true) ||
          !(benefits.includes(benefitsId.MATERNITY)
            ? m.maternitySelected.length
            : true) ||
          !(benefits.includes(benefitsId.DENTAL) ||
          benefits.includes(benefitsId.OPTICAL)
            ? m.dentalOpticalSelected
            : true);
      }

      return;
    });
    return error;
  },
  getStaticText: (key: string) => {
    const { isFrench } = useGetters(["isFrench"]);
    if (isFrench.value) return staticTextFrench[key];
    return staticText[key];
  },
  getBenefitName: (benefit: any) => {
    const { isFrench } = useGetters(["isFrench"]);
    if (isFrench.value) return benefit.name_fr ?? benefit.name;
    return benefit.name;
  },
};

export default format;
