var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"quotation-header",class:[
    {
      default: !_vm.props.defaultColor,
      bold: !_vm.props.defaultColor,
      'pink-bg': _vm.props.defaultColor,
    },
    ,
  ],style:({ height: _vm.rowHeight + 'px' }),attrs:{"id":_vm.props.label + '-quotationHeader'}},[_c('div',{staticClass:"cell-label",staticStyle:{"font-family":"OpenSans","font-weight":"bold"},style:({ paddingTop: _vm.rowHeight / 2 - 10 + 'px' })},[_vm._v(" "+_vm._s(_vm.props.label)+" ")]),_c('div',{staticClass:"cell-col",class:{
      'daffodil-bg': _vm.props.defaultColor,
      default: !_vm.props.defaultColor,
    },attrs:{"id":_vm.getId}},[(!_vm.format.isEmptyRow(_vm.props.plans, 'first'))?_c('div',{staticClass:"cell-row",class:{ bold: _vm.props.defaultColor, taller: _vm.props.defaultColor },staticStyle:{"font-family":"OpenSans"},style:(!_vm.props.defaultColor ? 'font-weight: bold;' : '')},_vm._l((_vm.gatherFirst(_vm.props.plan)),function(val,index){return _c('div',{key:index,staticClass:"cell-inner-col",class:{
          'daffodil-bg': _vm.props.defaultColor,
          default: !_vm.props.defaultColor,
        },attrs:{"id":_vm.props.label + '-first'}},[_vm._v(" "+_vm._s(val)+" ")])}),0):_vm._e(),(!_vm.format.isEmptyRow(_vm.props.plans, 'second'))?_c('div',{staticClass:"cell-row",staticStyle:{"font-family":"OpenSans"},style:(!_vm.props.defaultColor ? 'font-weight: bold;' : '')},_vm._l((_vm.gatherSecond(_vm.props.plan)),function(val,index){return _c('div',{key:index,staticClass:"cell-inner-col",class:{
          'daffodil-bg': _vm.props.defaultColor,
          default: !_vm.props.defaultColor,
        },attrs:{"id":_vm.props.label + '-second'}},[_vm._v(" "+_vm._s(val)+" ")])}),0):_vm._e(),(!_vm.format.isEmptyRow(_vm.props.plans, 'third'))?_c('div',{staticClass:"cell-row",staticStyle:{"font-family":"OpenSans"},style:(!_vm.props.defaultColor ? 'font-weight: bold;' : '')},_vm._l((_vm.gatherThird(_vm.props.plan)),function(val,index){return _c('div',{key:index,staticClass:"cell-inner-col",class:{
          'daffodil-bg': _vm.props.defaultColor,
          default: !_vm.props.defaultColor,
        },attrs:{"id":_vm.props.label + '-third'}},[_vm._v(" "+_vm._s(val)+" ")])}),0):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }