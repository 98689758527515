






































import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  props: ["label", "data", "isbenefit"],
  setup(props) {
    return { props };
  },
});
