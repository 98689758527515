


















































































import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
} from "@vue/composition-api";
import pipedriveField from "../enumerators/pipedriveField";
import { useActions, useGetters } from "vuex-composition-helpers";
import StepControl from "./StepControl.vue";
import router from "@/router";
export default defineComponent({
  props: ["step"],
  components: { StepControl },
  setup(props) {
    const { SET_SEARCH, SET_STEP, SET_SELECTION } = useActions([
      "SET_SEARCH",
      "SET_STEP",
      "SET_SELECTION",
    ]);
    const { search } = useGetters(["search"]);
    const error = ref(false);
    const form = reactive({
      email: "",
      phone: "",
      name: "",
    });
    const quotationId = ref("");
    onMounted(() => {
      if (search.value.value.length) {
        form[search.value.field] = search.value.value;
      }
    });

    const disabledEmail = computed(
      () => form.phone?.length > 0 || form.name?.length > 0
    );
    const disabledPhone = computed(
      () => form.email?.length > 0 || form.name?.length > 0
    );
    const disabledName = computed(
      () => form.phone?.length > 0 || form.email?.length > 0
    );

    const reset = () => {
      form.phone = "";
      form.email = "";
      form.name = "";
      SET_STEP(props.step);
      SET_SEARCH({ field: "", value: "" });
      SET_SELECTION({});
    };
    const validate = () => {
      const field = disabledPhone.value
        ? disabledEmail.value
          ? pipedriveField.NAME
          : pipedriveField.EMAIL
        : pipedriveField.PHONE;
      const value = disabledPhone.value
        ? disabledEmail.value
          ? form.name
          : form.email
        : form.phone;

      console.log("filed: ", field);
      console.log("value: ", value);
      SET_SEARCH({ field, value });
      SET_STEP(2);
      return;
    };

    const retrieveQuotation = () => {
      SET_STEP(0);
      router.push("/export/" + quotationId.value);
    };

    window.onbeforeunload = function() {
      if (
        disabledEmail.value ||
        disabledPhone.value ||
        disabledName.value ||
        quotationId.value
      )
        return "Are you sure you want to leave this page?";
    };

    return {
      form,
      validate,
      reset,
      error,
      disabledEmail,
      disabledPhone,
      disabledName,
      props,
      quotationId,
      retrieveQuotation,
    };
  },
});
