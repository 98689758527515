enum pipedriveField {
  EMAIL = "email",
  PHONE = "phone",
  BIRTHDAY = "8d95155c1f39a75adaf0bb7d129b8fbbfc1b9d6c",
  GENDER = "144307ad69178a63f78638eadf6341f4f5551006",
  POLICY_BENEFITS = "b3841c629299b1dc4f5ec4d0d22c06397c626d8b",
  BUDGET = "e9fade6a38907a00f2145b9332f9c5f8d7bf63bb",
  // BUDGET = "value",
  DEDUCTABLE = "68b08077157a03bdb9cb10686f9dd061a5998c4e",
  ROOM_TYPE = "cbfd80497eaddcc1f4a7b59aa716b5c2bdccde35",
  OTHER_BENEFITS = "3ffab752eb485976bbd1d5973bf9fcaf4964f986",
  NAME = "name",
  ID = "id",
  ORGANIZATION_FAMILY = "d4cd964220d6a2165ab0fa41bdd1776a012b1f3a",
  TITLE = "title",
  COVERAGE = "e53b7c6f9a88afa35bb68d4b9a6958b4c59c0c44",
  EXCLUDE_COVERAGE = "f0f67f185ae038ffb71c8afb52741660a74a49f5",
  // CURRENCY = "currency",
  CURRENCY = "e9fade6a38907a00f2145b9332f9c5f8d7bf63bb_currency",
  PROVIDERS = "providers",
  ORGANIZATION_OWNER = "e647db58edf55a9382f5977d92dc4268aef2fd1c",
  VALUE = "value",
}
export default pipedriveField;
