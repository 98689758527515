








































































































































































































































































































































































import pipedriveField from "@/enumerators/pipedriveField";
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
} from "@vue/composition-api";
import { useGetters, useActions } from "vuex-composition-helpers";
import fieldServices from "../services/field";
import PipedriveServices from "../enumerators/pipedriveServices";
import StaticText from "../enumerators/export";
import BenefitsId from "../enumerators/benefitsId";
import StepControl from "./StepControl.vue";
import format from "../services/format";
import API from "@/plugins/axios";
import convert from "@/services/convert";
import session from "@/services/session";
// import benefitsId from "../enumerators/benefitsId";
export default defineComponent({
  props: ["pipedriveFieldInfo"],
  components: { StepControl },
  setup(props) {
    const {
      clientSelection,
      dealSelection,
      quotation,
      searchSelection,
      area,
      mixOptions,
      network,
    } = useGetters([
      "clientSelection",
      "dealSelection",
      "quotation",
      "searchSelection",
      "area",
      "mixOptions",
      "network",
    ]);
    const {
      SET_STEP,
      SET_QUOTATION,
      SET_SEARCHSELECTION,
      SET_PREMIUMS,
      SET_CREATE_QUOTATION,
      CLEAR_SEARCHSELECTION,
      SET_IS_FRENCH,
    } = useActions([
      "SET_STEP",
      "SET_QUOTATION",
      "SET_SEARCHSELECTION",
      "SET_PREMIUMS",
      "SET_CREATE_QUOTATION",
      "CLEAR_SEARCHSELECTION",
      "SET_IS_FRENCH",
    ]);
    const form = reactive({
      dealId: null,
      name: "",
      age: "",
      gender: null,
      benefits: [],
      // excludeBenefits: [],
      budgetMin: "0",
      budget: "",
      providers: [],
      frenchProviders: [],
      roomType: [],
      isOrganization: false,
      organizationMembers: [],
      deductible: {
        min: "0",
        max: "0",
      },
      mix: [],
      area: [],
      excludeArea: [],
      network: [],
      mixingEnable: false,
      deductibleEnable: false,
      showFrenchProviders: false,
      cfe: false,
    });
    const catalogue = reactive({
      benefits: [],
      // excludeBenefits: [],
      roomType: [],
      gender: [],
      providers: [],
      frenchProviders: [],
      area: [],
      excludeArea: [],
      network: [],
    });
    const origData = reactive({
      benefits: dealSelection.value?.policyBenefits,
      budgetMin: 0,
      budget: dealSelection.value?.budget,
      roomType: dealSelection.value?.roomType,
    });
    const options = reactive({
      deductible: false,
      mixing: false,
    });
    const loading = ref(false);

    const areaList = area.value;

    const benefitList = [
      {
        name: "In-patient/hospitalization",
        id: BenefitsId.INPATIENT,
      },
      {
        name: "Out-patient/clinical",
        id: BenefitsId.OUTPATIENT,
      },
      {
        name: "Maternity",
        id: BenefitsId.MATERNITY,
      },
      {
        name: "Dental",
        id: BenefitsId.DENTAL,
      },
      {
        name: "Optical",
        id: BenefitsId.OPTICAL,
      },
    ];

    onMounted(async () => {
      if (searchSelection.value?.dealId !== dealSelection.value?.id)
        CLEAR_SEARCHSELECTION();

      catalogue.gender = fieldServices.getFieldOption(
        pipedriveField.GENDER,
        props.pipedriveFieldInfo.person
      ).catalogueOption;

      catalogue.benefits = fieldServices.getProvidersOption(
        benefitList
      ).catalogueOption;
      // catalogue.excludeBenefits = fieldServices.getProvidersOption(
      //   benefitList
      // ).catalogueOption;

      // catalogue.benefits = fieldServices.getFieldOption(
      //   pipedriveField.POLICY_BENEFITS,
      //   props.pipedriveFieldInfo.deal
      // ).catalogueOption;

      // catalogue.excludeBenefits = fieldServices.getFieldOption(
      //   pipedriveField.POLICY_BENEFITS,
      //   props.pipedriveFieldInfo.deal
      // ).catalogueOption;

      catalogue.roomType = fieldServices.getFieldOption(
        pipedriveField.ROOM_TYPE,
        props.pipedriveFieldInfo.deal
      ).catalogueOption;

      catalogue.providers = fieldServices.getProvidersOption(
        props.pipedriveFieldInfo.providers.filter((p) => !p.frenchPlan)
      ).catalogueOption;

      catalogue.frenchProviders = fieldServices.getProvidersOption(
        props.pipedriveFieldInfo.providers.filter((p) => p.frenchPlan)
      ).catalogueOption;

      catalogue.area = fieldServices.getProvidersOption(
        areaList,
        "displayName"
      ).catalogueOption;

      catalogue.excludeArea = fieldServices.getProvidersOption(
        areaList,
        "displayName"
      ).catalogueOption;

      catalogue.network = fieldServices.getProvidersOption(
        network.value
      ).catalogueOption;

      if (!clientSelection.value.id && !searchSelection.value) return;
      form.dealId = dealSelection.value.id;
      form.name = searchSelection.value?.name ?? clientSelection.value?.name;
      form.age = searchSelection.value?.age ?? clientSelection.value?.age;
      form.budget =
        searchSelection.value?.budget ?? dealSelection.value?.budget;
      form.budgetMin = "0";

      form.gender = fieldServices.getSelectedOption(
        searchSelection.value?.gender ?? clientSelection.value?.gender.value,
        // clientSelection.value?.gender.value,
        catalogue.gender
      );

      form.benefits = fieldServices.getMultipleSelectedOption(
        searchSelection.value?.benefits ?? dealSelection.value?.policyBenefits,
        catalogue.benefits
      );

      // form.excludeBenefits = fieldServices.getMultipleSelectedOption(
      //   searchSelection.value?.excludeBenefits ?? [
      //     catalogue.excludeBenefits.find((item) => item.text === "Maternity"),
      //   ],
      //   catalogue.excludeBenefits
      // );
      // console.log("catalogue.excludeBenefits: ", catalogue.excludeBenefits);
      form.mixingEnable = searchSelection.value?.mixingEnable ?? false;
      form.deductibleEnable = searchSelection.value?.deductibleEnable ?? false;

      form.showFrenchProviders =
        searchSelection.value?.showFrenchProviders ?? false;
      form.cfe = searchSelection.value?.cfe ?? false;

      form.mix =
        searchSelection.value?.mix ?? format.getMixOptions(mixOptions.value);

      form.deductible = searchSelection.value?.deductible ?? {
        min: "0",
        max: "0",
      };

      form.providers = fieldServices.getMultipleSelectedOption(
        searchSelection.value?.providers ?? catalogue.providers,
        catalogue.providers
      );

      form.frenchProviders = fieldServices.getMultipleSelectedOption(
        searchSelection.value?.frenchProviders ?? catalogue.frenchProviders,
        catalogue.frenchProviders
      );

      form.roomType = fieldServices.getMultipleSelectedOption(
        searchSelection.value?.roomType ?? dealSelection.value?.roomType,
        catalogue.roomType
      );

      form.area = fieldServices.getMultipleSelectedOption(
        searchSelection.value?.area ??
          catalogue.area.filter((area) => area.value === 1),
        catalogue.area
      );
      form.excludeArea = fieldServices.getMultipleSelectedOption(
        searchSelection.value?.excludeArea ??
          catalogue.excludeArea.filter((area) => area.value === 12),
        catalogue.excludeArea
      );
      form.network = searchSelection.value?.network ?? catalogue.network;
      form.isOrganization =
        searchSelection.value?.isOrganization ??
        clientSelection.value.Organization
          ? true
          : false;

      if (searchSelection.value?.organizationMembers) {
        form.organizationMembers = searchSelection.value.organizationMembers;
      } else if (form.isOrganization) {
        const orgId = clientSelection.value.Organization.value;

        const resultOrgPersons = await API.pipedrive().get(
          PipedriveServices.ORGANIZATIONS +
            orgId +
            PipedriveServices.PERSONS +
            "?api_token=" +
            process.env.VUE_APP_PIPEDRIVE_TOKEN
        );

        form.organizationMembers = resultOrgPersons.data.data.map((item) => {
          item.age = convert.dateToAge(item[pipedriveField.BIRTHDAY]);

          item.gender = fieldServices.getSelectedOption(
            item[pipedriveField.GENDER],
            catalogue.gender
          );
          item.disableName = item.name?.length ? true : false;
          item.disableAge = item.age ? true : false;
          item.enabled = true;
          // catalogue.gender.find((gender) => {
          //   return gender.value === parseInt(item[pipedriveField.GENDER]);
          // })?.text ?? null;

          return item;
        });
      } else {
        form.organizationMembers = [
          {
            age: form.age,
            gender: form.gender,
            name: form.name,
            genderStr: getGenderStr(form.gender, catalogue.gender),
          },
        ];
      }
    });

    const hasName =
      clientSelection.value?.name && clientSelection.value?.name?.length !== 0;
    const hasAge =
      clientSelection.value?.age && clientSelection.value?.age?.length !== 0;

    // const disableName = (name: string): boolean => name.length !== 0;
    // const disableAge = (age: number): boolean => age && age > 0;
    // const updateMixOptions = computed(()=>{
    //   return [];
    // })

    const disableSearch = computed(() => {
      return !(
        (form.isOrganization || JSON.stringify(form.name).length) &&
        (form.isOrganization || JSON.stringify(form.age).length) &&
        (form.isOrganization || form.gender) &&
        form.benefits.length &&
        JSON.stringify(form.budget).length &&
        form.roomType.length &&
        form.providers.length
      );
    });
    const joinId = (list, unique = true) => {
      const res = unique
        ? [...new Set(list.map((id) => JSON.stringify(id)))].join(";")
        : list.map((id) => JSON.stringify(id)).join(";");
      return res;
    };

    // const comparePolicy = (a, b) => (a.id > b.id ? 1 : -1);

    const getChipColor = (data, field) => {
      let isNew = false;
      let isRepeated = false;
      if (field == "room") {
        isNew = origData.roomType.find((ele) => ele.value == data.item.value)
          ? false
          : true;
      } else if (field == "benefits") {
        isNew = origData.benefits.find((ele) => ele.value == data.item.value)
          ? false
          : true;
        // isRepeated = form.excludeBenefits.find((ele) => ele == data.item.value)
        //   ? true
        //   : false;
        // } else if (field == "excludeBenefits") {
        //   isNew = data.item.text !== "Maternity";
        //   isRepeated = form.benefits.find((ele) => ele == data.item.value)
        //     ? true
        //     : false;
      } else if (field == "area") {
        isNew = data.item.text !== "Worldwide";
        isRepeated = form.excludeArea.find((ele) => ele == data.item.value)
          ? true
          : false;
      } else if (field == "excludeArea") {
        isNew = data.item.text !== "USA";
        isRepeated = form.area.find((ele) => ele == data.item.value)
          ? true
          : false;
      }
      return isRepeated
        ? "rgb(255, 219, 219)"
        : isNew
        ? "rgb(252, 237, 201)"
        : null;
    };
    const removeChip = (item, model) => {
      model.splice(model.indexOf(item.value), 1);
    };

    const containSameValue = (arr1, arr2) => {
      let repeated = false;
      arr1.map((val1) => {
        if (repeated) return;
        arr2.map((val2) => {
          if (!repeated) repeated = val1 === val2;
        });
      });
      return repeated;
    };

    const getGenderStr = (id, genderlist) =>
      genderlist.find((gen) => gen.value === id)?.text ?? "";

    const displayMixing = () => {
      // console.log("form.providers: ", form.providers);
      // console.log("benefitList: ", form.benefits);
      // console.log("mixOptions: ", mixOptions);
      const hasMixingProvider = form.providers.some((id) =>
        mixOptions.value.map((o) => o.id).includes(id)
      );
      const onlyInpatient =
        form.benefits.length === 1 && form.benefits[0] === BenefitsId.INPATIENT;
      // console.log("onlyInpatient: ", onlyInpatient);

      if (hasMixingProvider && !onlyInpatient) return true;
      else {
        form.mixingEnable = false;
        return false;
      }
    };

    const validate = async () => {
      // console.log("form.benefits: ", JSON.stringify(form.benefits));
      loading.value = true;
      const benQuery = joinId(form.benefits);
      // const excludeBenQuery = joinId(form.excludeBenefits);
      // const roomTypeQuery = joinId(form.roomType);
      // const providersQuery = joinId(form.providers);
      const genderList = form.isOrganization
        ? form.organizationMembers
            .filter((mem) => mem.enabled)
            .map(
              (mem) =>
                // parseInt(mem[pipedriveField.GENDER])
                mem.gender
            )
        : [form.gender];
      const ageList = form.isOrganization
        ? form.organizationMembers
            .filter((mem) => mem.enabled)
            .map((mem) => parseInt(mem.age))
        : [parseInt(form.age)];

      // console.log("geners: ", form.gender);
      if (benQuery.length === 0) {
        alert("Please choose benefits");
        loading.value = false;
        return;
      }
      if (form.area.length === 0) {
        alert("Please choose at least one area to be included");
        loading.value = false;
        return;
      }
      if (containSameValue(form.area, form.excludeArea)) {
        alert("Area cannot be included and excluded at the same time");
        loading.value = false;
        return;
      }
      if (
        form.mixingEnable &&
        displayMixing() &&
        format.checkMixOptions(form.mix, form.providers, form.benefits)
      ) {
        alert("Mixing options cannot be empty");
        loading.value = false;
        return;
      }

      if (form.isOrganization) {
        let alertMsg = "";
        form.organizationMembers.map((mem) => {
          mem.genderStr = getGenderStr(mem.gender, catalogue.gender);
          if (!alertMsg.length && (!mem.gender || !mem.age || !mem.name)) {
            alertMsg = "Please enter all the client information";
            return;
          }
        });
        if (alertMsg.length) {
          alert(alertMsg);
          loading.value = false;
          return;
        }
      }

      SET_SEARCHSELECTION(form);
      const body = {
        inpatient: true,
        outpatient: form.benefits.find(
          (ben) =>
            ben === BenefitsId.OUTPATIENT ||
            ben === BenefitsId.MATERNITY ||
            ben === BenefitsId.DENTAL ||
            ben === BenefitsId.OPTICAL
        )
          ? true
          : false,
        maternity: form.benefits.find((ben) => ben === BenefitsId.MATERNITY)
          ? true
          : false,
        dental: form.benefits.find((ben) => ben === BenefitsId.DENTAL)
          ? true
          : false,
        optical: form.benefits.find((ben) => ben === BenefitsId.OPTICAL)
          ? true
          : false,
        minBudget: parseInt(form.budgetMin),
        maxBudget: parseInt(form.budget),
        family: ageList.map((age, index) => {
          return {
            age: age,
            gender: genderList[index],
          };
        }),
        providers: form.showFrenchProviders
          ? form.frenchProviders
          : form.providers,
        coverageInclude: form.area,
        coverageExclude: form.excludeArea,
        roomType: form.roomType,
        deductible: {
          min: form.deductibleEnable ? parseInt(form.deductible.min) : 0,
          max: form.deductibleEnable ? parseInt(form.deductible.max) : 0,
        },
        mixing: form.mixingEnable
          ? format.getMixingReq(form.mix, form.providers, form.benefits)
          : [],
        network: form.network.map((n) => n.value ?? n),
        cfe: form.showFrenchProviders && form.cfe,
        page: 0,
        sort: 0,
      };

      const res = await API.strapi(session.getStoredToken())
        .post("/iqt/findpolicies", body)
        .catch((err) => {
          loading.value = false;
          console.log(err);
          if (err.response?.statusText === StaticText.UNAUTHORIZED)
            session.logout();
        });

      if (!res || !res["data"] || !res["data"].length) {
        alert("no data");
        loading.value = false;
        return;
      }

      SET_IS_FRENCH(form.showFrenchProviders);

      const premiumList = format.getPremiumList(res.data);
      SET_PREMIUMS(premiumList);

      //check if res is the same as stored quotation
      const storeQuotation = JSON.parse(JSON.stringify(quotation?.value));
      if (
        storeQuotation.res &&
        JSON.stringify(storeQuotation.res) === JSON.stringify(res?.data)
      ) {
        // loading.value = false;
        // SET_STEP(5);
        // return;
        SET_CREATE_QUOTATION(false);
      } else SET_CREATE_QUOTATION(true);
      SET_QUOTATION({
        id: quotation.value.id,
        res: res.data,
      });

      // //create new quotation number
      // const clientInfo: Array<{
      //   name: string;
      //   age: number;
      //   gender?: number;
      //   pipedriveId?: number;
      // }> = [];
      // if (!form.isOrganization)
      //   clientInfo.push({
      //     name: clientSelection.value.name,
      //     age: clientSelection.value.age,
      //   });
      // else {
      //   form.organizationMembers.map((mem) => {
      //     mem.genderStr = getGenderStr(mem.gender, catalogue.gender);
      //     clientInfo.push({
      //       name: mem.name,
      //       age: mem.age,
      //       gender: mem.gender,
      //       pipedriveId: mem.id,
      //     });
      //   });
      // }
      // const entry = {
      //   searchResult: res?.data,
      //   client: clientInfo,
      //   user: user?.value.pipedriveId,
      // };

      // // create entry for quotation
      // const quotationId = await API.strapi(session.getStoredToken())
      // // API.strapi(session.getStoredToken())
      //   .post(StrapiServices.CREATE_QUOTATION_NUMBER, entry)
      //   .catch((err) => {
      //     console.log(err);
      //     if (err.response.statusText === StaticText.UNAUTHORIZED)
      //       session.logout();
      //   })
      //   // .then((quotationNumRes: any) => {
      //   //   SET_QUOTATION({
      //   //     id: quotationNumRes?.data,
      //   //     res: res.data,
      //   //   });
      //   // });
      // if (quotationId) {
      //   SET_QUOTATION({
      //     id: quotationId?.data,
      //     res: res.data,
      //   });
      // }
      loading.value = false;
      SET_STEP(5);
    };
    return {
      form,
      catalogue,
      validate,
      disableSearch,
      loading,
      hasName,
      hasAge,
      getChipColor,
      removeChip,
      origData,
      pipedriveField,
      options,
      mixOptions,
      displayMixing,
      BenefitsId,
    };
  },
});
