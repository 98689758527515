<template>
  <div class="header">
    <div class="panel">
      <span class="left">
        <v-avatar class="avatar" color="white" size="35">{{
          user.username.substring(0, 1).toUpperCase()
        }}</v-avatar>
        <span class="username">{{ user.username }}</span></span
      >
      <span @click="goToHomepage" class="name middle">{{ title }}</span>
      <span class="right"
        ><v-btn class="logout-btn" @click="logout">Logout</v-btn></span
      >
    </div>
    <!-- <Progression :progress="step" /> -->
  </div>
</template>
<script>
import router from "@/router";
import { defineComponent, computed } from "@vue/composition-api";
import { useGetters, useActions } from "vuex-composition-helpers";
import Progression from "../components/Progression";

export default defineComponent({
  props: [],
  components: { Progression },
  setup(props) {
    const { step, user } = useGetters(["step", "user"]);
    const { SET_STEP } = useActions(["SET_STEP"]);

    const title = computed(() => {
      switch (step.value) {
        case 1:
          return "Please enter your client’s details";
        case 2:
          return "Select your client";
        case 3:
          return "Select the lead or deal";
        case 4:
          return "Deal details";
        default:
          return "Internal Quotation Tool";
      }
    });

    const logout = () => {
      localStorage.removeItem("token");
      router.go(0);
      return;
    };
    const goToHomepage = () => {
      SET_STEP(1);
      if(window.location.pathname == "/" ) router.go(0)
      else {

        router.replace("/");
        router.go()
      }
    };

    return { props, user, logout, goToHomepage, step, title };
  },
});
</script>
<style lang="scss" scoped>
@import "../common/common.scss";
.header {
  //   background: linear-gradient(300deg, #961636, white);
  background: $main-red;
  padding: 10px 0;
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
  .panel {
    width: 90%;
    // text-align: end;
    display: flex;
    // justify-content: space-between;
    margin: auto;
    .username {
      margin-left: 5px;
      color: white;
      font-size: 20px;
    }

    .name {
      color: white;
      font-size: 20px;
      font-weight: 800;
      cursor: pointer;
    }

    .logout-btn {
      border: solid 1px white;
      background-color: $main-red;
      color: white;
      float: right;
      //   background-color: white;
      //   color: $main-red;
    }

    .left {
      flex: 1;
      float: left;

      .avatar {
        color: $main-red;
        font-weight: 800;
        font-size: 20px;
        margin-right: 5px;
      }
    }

    .middle {
      flex: 1;
      text-align: center;
    }

    .right {
      flex: 1;
    }
  }
}

@media print {
  .header {
    display: none;
  }
}
</style>
