import Lead from "@/dto/Lead";
import Catalogue from "@/enumerators/catalogue";
import pipedriveField from "@/enumerators/pipedriveField";
import pipedriveServicesEnum from "@/enumerators/pipedriveServices";
import convert from "@/services/convert";
import API from "@/plugins/axios";

export default {
  filterLead: (
    listLeadDeal: Array<any>,
    personId: number | null,
    organizationId: number | null
  ): Array<any> => {
    return listLeadDeal.filter(
      (leadDeal: any) =>
        leadDeal.item.type == Catalogue.LeadDealType.LEAD &&
        // leadDeal.item.person?.id == personId &&
        leadDeal.item.organization?.id == organizationId
    );
  },
  filterOpenDeal: (
    listLeadDeal: Array<any>,
    personId: number | null,
    organizationId: number | null
  ): Array<any> => {
    return listLeadDeal.filter(
      (leadDeal: any) =>
        leadDeal.item.type == Catalogue.LeadDealType.DEAL &&
        leadDeal.item.status == Catalogue.DealStatus.OPEN &&
        // leadDeal.item.person?.id == personId &&
        leadDeal.item.organization?.id == organizationId
    );
  },
  createListLeadDeal: async (
    listDealLead: Array<any>,
    pipedriveServices: string,
    currencyExchange: Array<any>
  ): Promise<any> => {
    const listDeal = new Array<Lead>();
    const promise = listDealLead.map(async (dealLead: any) => {
      const result = await API.pipedrive().get(
        pipedriveServices +
          dealLead.item.id +
          "?api_token=" +
          process.env.VUE_APP_PIPEDRIVE_TOKEN
      );
      const currencyName =
        pipedriveServices === pipedriveServicesEnum.LEADS
          ? result.data.data[pipedriveField.VALUE]?.currency ?? "USD"
          : result.data.data[pipedriveField.CURRENCY];
      const rate =
        currencyExchange.find((f) => f.name == currencyName)?.rate ?? 1;
      const lead = new Lead();
      lead.id = result.data.data[pipedriveField.ID];
      lead.title = result.data.data[pipedriveField.TITLE];
      lead.benefits = convert.toMultiCatalogue(
        pipedriveField.OTHER_BENEFITS,
        result.data.data[pipedriveField.OTHER_BENEFITS]
      );
      lead.budget = parseFloat(
        (
          (pipedriveServices === pipedriveServicesEnum.LEADS
            ? result.data.data[pipedriveField.VALUE]?.amount ?? 0
            : result.data.data[pipedriveField.BUDGET]) / rate
        ).toFixed(2)
      );
      lead.deductable = result.data.data[pipedriveField.DEDUCTABLE];
      lead.policyBenefits = convert.toMultiCatalogue(
        pipedriveField.POLICY_BENEFITS,
        result.data.data[pipedriveField.POLICY_BENEFITS]
      );
      lead.roomType = convert.toMultiCatalogue(
        pipedriveField.ROOM_TYPE,
        result.data.data[pipedriveField.ROOM_TYPE]
      );
      lead.coverage = convert.toMultiCatalogue(
        pipedriveField.COVERAGE,
        result.data.data[pipedriveField.COVERAGE]
      );
      lead.coverageExclude = convert.toMultiCatalogue(
        pipedriveField.EXCLUDE_COVERAGE,
        result.data.data[pipedriveField.EXCLUDE_COVERAGE]
      );
      listDeal.push(lead);
    });
    await Promise.all(promise);
    return listDeal;
  },
};
