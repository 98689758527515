import CatalogueOption from "@/dto/CatalogueOption";
import moment from "moment";

const convert = {
  dateToAge: (data: any) => {
    const age = Number.parseFloat(moment(data).fromNow(true));
    return !isNaN(age) ? age : null;
  },
  toCatalogue: (text: string, value: string | number) => {
    const catalogueOption = new CatalogueOption();
    catalogueOption.value = value;
    catalogueOption.text = text;
    return catalogueOption;
  },
  toMultiCatalogue: (key: string, listValue: string) => {
    const multiCatalogue = new Array<CatalogueOption>();
    if (listValue) {
      const valueArray = listValue.toString().split(",");
      valueArray.map((value: string) => {
        multiCatalogue.push(convert.toCatalogue(key, value));
      });
    }
    return multiCatalogue;
  },
};
export default convert;
