<template>
  <div class="step-control">
    <v-btn
      color="success"
      class="my-2 back-button"
      @click="goBack"
      :disabled="!previousStep"
    >
      &lt; Back
    </v-btn>

    <span class="step-title">{{ stepTitle }}</span>

    <v-btn
      color="success"
      class="my-2 next-button"
      :class="{
        hidden: !(props.currentStep >= 5),
      }"
      @click="goNext"
      :disabled="!nextStep"
    >
      {{ nextLabel }}
    </v-btn>
  </div>
</template>
<script>
import { ref, watchEffect, defineComponent } from "@vue/composition-api";
import { useActions, useGetters } from "vuex-composition-helpers";
import format from "../services/format";

export default defineComponent({
  props: ["currentStep", "totalStep"],
  setup(props) {
    const { step, quotation } = useGetters(["step", "quotation"]);
    const { SET_STEP, CLEAR_SEARCHSELECTION } = useActions([
      "SET_STEP",
      "CLEAR_SEARCHSELECTION",
    ]);

    const previousStep = ref(null);
    const nextStep = ref(null);
    const stepTitle = ref("");
    const nextLabel = ref("Next");

    watchEffect(() => {
      previousStep.value =
        props.currentStep - 1 > 0 ? props.currentStep - 1 : null;

      if (props.currentStep == 5) {
        // stepTitle.value = quotation.value.id;
        nextLabel.value = "Preview";
        nextStep.value = props.currentStep + 1;
      } else if (props.currentStep == 6) {
        stepTitle.value = quotation.value.id;
        nextLabel.value = "Export";
        nextStep.value = props.currentStep;
      } else {
        stepTitle.value = "";
        nextLabel.value = "Next >";
      }
    });

    const goBack = () => {
      if (props.currentStep == 2) CLEAR_SEARCHSELECTION();
      SET_STEP(previousStep.value);
    };
    const goNext = () => {
      if (props.currentStep == 5 && format.previewExport(quotation))
        SET_STEP(6);
      else if (props.currentStep == 6) format.generateReport(quotation);
    };
    return {
      props,
      step,
      goBack,
      goNext,
      previousStep,
      nextStep,
      nextLabel,
      stepTitle,
    };
  },
});
</script>
<style lang="scss" scoped>
@import "../common/common.scss";
.step-control {
  bottom: 0;
  width: 90%;
  justify-content: space-between;
  //   position: fixed;
  //   top: 20px;
  display: flex;
  // margin-right: 5%;
  // margin-left: 5%;
  margin: auto;
  // text-align: center;
  color: white;
  font-weight: 600;
  height: 55px;
  .step-title {
    text-align: center;
    color: $main-red;
    padding-top: 12px;
    font-size: 18px;
    font-weight: 400;
    flex: auto;
    margin-left: 64px;
  }
  .my-2 {
    background: $main-red !important;
  }
  .back-button {
    position: absolute;
  }
  .next-button {
    // position: absolute;
    float: right;
  }
}

.hidden {
  display: none;
}
</style>
