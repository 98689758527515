import { render, staticRenderFns } from "./Clients.vue?vue&type=template&id=3c3bee7d&scoped=true&"
import script from "./Clients.vue?vue&type=script&lang=ts&"
export * from "./Clients.vue?vue&type=script&lang=ts&"
import style0 from "./Clients.vue?vue&type=style&index=0&id=3c3bee7d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c3bee7d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VCard,VCardText,VContainer,VSimpleTable})
