<template>
  <div class="discount">
    <v-card>
      <v-card-title class="text-h5 lighten-2 header">
        Annual Premium
      </v-card-title>

      <v-row>
        <v-icon class="icon" size="middle">mdi-currency-usd</v-icon>
        <v-text-field
          v-model="newPremium"
          type="number"
          label="Amount"
          min="0"
          :autofocus="true"
          @keyup.enter="applyPremium()"
        ></v-text-field>
      </v-row>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="second-button" text @click="applyPremium()">
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import format from "../services/format";
import { defineComponent, ref } from "@vue/composition-api";
import { useGetters, useActions } from "vuex-composition-helpers";

export default defineComponent({
  props: ["updateDialog", "premiumDetails"],
  setup(props) {
    const { premiums } = useGetters(["premiums"]);
    const { SET_PREMIUMS } = useActions(["SET_PREMIUMS"]);
    const newPremium = ref(null);

    const applyPremium = () => {
      if (parseFloat(newPremium.value) <= 0) {
        alert("Value cannot be smaller than 0, please enter again");
        return;
      }
      //2 decimal place at most
      newPremium.value = Math.round(newPremium.value * 100) / 100;
      SET_PREMIUMS(
        format.updatePremium(
          props.premiumDetails.policyID,
          "premium",
          newPremium,
          premiums
        )
      );
      props.updateDialog(false, props.premiumDetails.policyID, "premiumDialog");
    };

    return {
      newPremium,
      applyPremium,
    };
  },
});
</script>
<style lang="scss" scoped>
@import "../common/common.scss";
.discount {
  .header {
    justify-content: center;
    background: $main-red;
    color: white;
    font-weight: 600;
  }
  .row {
    margin: 15px;
    .icon {
      align-self: center;
      padding-right: 2%;
    }
    .seperator {
      align-self: center;
      padding: 5%;
    }
    .v-input--is-disabled {
      opacity: 0.5;
      -moz-opacity: 0.5;
    }
  }
  .second-button {
    color: $main-red;
    border-color: $main-red;
    text-align: center;
  }
}

@media print {
  .discount {
    display: none;
  }
}
</style>
