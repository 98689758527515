import CatalogueOption from "./CatalogueOption";

export default class Lead {
  constructor() {
    this.policyBenefits = new Array<CatalogueOption>();
    this.roomType = new Array<CatalogueOption>();
    this.benefits = new Array<CatalogueOption>();
    this.coverage = new Array<CatalogueOption>();
    this.coverageExclude = new Array<CatalogueOption>();
  }
  id!: string;
  policyBenefits: Array<CatalogueOption>;
  budget!: number;
  deductable!: number;
  roomType!: Array<CatalogueOption>;
  benefits!: Array<CatalogueOption>;
  title!: string;
  coverage: Array<CatalogueOption>;
  coverageExclude: Array<CatalogueOption>;
}
