enum pipedriveServices {
  USERS = "/users/",
  SEARCH_PERSON = "/persons/search/?",
  ITEM_SEARCH = "/itemSearch/?",
  LEADS = "/leads/",
  DEALS = "/deals/",
  PERSONS = "/persons/",
  ORGANIZATIONS = "/organizations/",
}
export default pipedriveServices;
