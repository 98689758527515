import CatalogueOption from "./CatalogueOption";
import Lead from "./Lead";
import Person from "./Person";

export default class PersonDetails extends Person {
  constructor() {
    super();
    this.lead = new Array<Lead>();
    this.deal = new Array<Lead>();
    this.people_count = 1;
  }
  age!: number | null;
  gender!: CatalogueOption;
  lead!: Array<Lead>;
  deal!: Array<Lead>;
  people_count: number;
  type!: CatalogueOption;
}
