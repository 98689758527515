













import { defineComponent } from "@vue/composition-api";
import CompareCell from "./CompareCell.vue";
export default defineComponent({
  components: {
    CompareCell,
  },
  props: ["benefitsName", "benefits"],

  setup(props) {
    const isBenefitInclude = (id: number) => {
      const result = [];
      props.benefits.map((benef: any) => {
        // console.log("search for=", benef);
        const find = benef.find((benef: any) => benef == id && benef.include);
        if (find) {
          result.push(find);
        } else {
          result.push(null);
        }
        //   benef.map(b => {

        //   })
        // const find = b.find(
        //   (b: any) => b.benefits_name?.pipedriveId == id && b.include
        // );
        // console.log("search for=", b);
        // if (!find) {
        //   console.log("NO TFOUND");
        //   //result.push(false);
        // } else {
        //   console.log("find=", find);
        //   result.push(b);
        // }
        //(!find && result.push(false)) || (find && result.push(b));
      });
      return result;
    };
    return { props, isBenefitInclude };
  },
});
