import strapiServices from "@/enumerators/strapiServices";
import API from "@/plugins/axios";
import format from "./format";
import router from "@/router";
import session from "./session";
export default {
  verifyToken: async (): Promise<any> => {
    const token = localStorage.getItem("token");
    if (token) {
      const item = JSON.parse(token);

      if (new Date().getTime() > item.expiry) {
        localStorage.removeItem("token");
        return false;
      }
      const newItem = {
        value: item.value,
        expiry: format.getExpiry(),
      };
      localStorage.setItem("token", JSON.stringify(newItem));
      return await API.strapi(item.value)
        .get(strapiServices.USER_ME)
        .catch((err) => {
          console.log(err);
          session.logout();
          return false;
        });
    }
    return false;
  },
  getStoredToken: () => {
    const token = JSON.parse(localStorage.getItem("token"));
    return token.value;
  },
  logout: () => {
    localStorage.removeItem("token");
    router.go(0);
    return;
  },
};
