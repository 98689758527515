enum benefitsId {
  INPATIENT = 40,
  OUTPATIENT = 39,
  MATERNITY = 41,
  DENTAL = 42,
  OPTICAL = 144,
  INPATIENT_NAME = "inpatient",
  OUTPATIENT_NAME = "outpatient",
  MATERNITY_NAME = "maternity",
  DENTAL_OPTICAL_NAME = "dentalOptical",
}
export default benefitsId;
