<template>
  <div class="progressionBar">
    <v-progress-linear type="number" :value="currentStep"></v-progress-linear>
    <StepControl :currentStep="parseInt(progress)" />
  </div>
</template>
<script>
import { computed, defineComponent } from "@vue/composition-api";
import StepControl from "../components/StepControl";

export default defineComponent({
  props: ["progress"],
  components: { StepControl },
  setup(props) {
    const totalStep = 6;
    const currentStep = computed(() => {
      return (100 / (totalStep - 1)) * (props.progress - 1);
    });

    return { props, currentStep, totalStep };
  },
});
</script>
<style lang="scss" scoped>
@import "../common/common.scss";
.progressionBar {
  position: fixed;
  display: block;
  bottom: 0;
  width: 100%;
  z-index: 999;
  // background: linear-gradient(300deg, $main-red, white);
  background: white;
}
@media print {
  .progressionBar {
    display: none;
  }
}
</style>
